import { useRef, useEffect } from 'react';
import { useUpdateElementsToScan } from '../../../switch-access/SwitchAccessHooks';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import { useSwitchAccess } from '../../../switch-access/context/SwitchAccessContextProvider';
import {
  ACTIVITY_TYPE_SORTING_ACTIVITY,
  ACTIVITY_TYPE_SEQUENCING_ACTIVITY,
  ACTIVITY_TYPE_MATCHING_ACTIVITY,
  ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA,
  ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA,
  ACTIVITY_TYPE_GAME_ACTIVITY,
  ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY,
} from '../../../../AppConstants';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { REINFORCER_STYLE_KEY } from '../../../../constants/PlayPreferenceConstant';

export const useGetElementsForSwitchAccess = ({
  lessonPageContainerRef,
  setContinueFromIndex,
  finishedActivityIndexes,
}) => {
  const lessonActivityTopBarRef = useRef(null);
  const lessonActivityBottomBarRef = useRef(null);
  const { lessonPlayDomain } = useLessonPlay();
  const { currentPageIndex, isMediaPlaying, userProfile } = lessonPlayDomain.domainData;
  const { switchAccessibility, reinforcerStyle } = userProfile;
  const { switchAccessDomain, switchAccessElementDomain } = useSwitchAccess();
  const { isDialogShowing } = switchAccessDomain.domainData;

  const skipAppBarFlagRef = useRef(null);

  const savedIndex = useRef(-1);

  const {
    updateElementsToScan,
  } = useUpdateElementsToScan();

  const getElementsForSwitchAccessFunction = async ({
    skipAppBarFlag,
  } = {
    skipAppBarFlag: false,
    saveCurrentIndex: false,
    currentSelectedIndex: { current: -1 },
    resumeLastSaveIndex: false,
  }) => {
    skipAppBarFlagRef.current = skipAppBarFlag;
    if (lessonPlayDomain.domainData.isMediaPlaying) {
      updateElementsToScan([]);
      return [];
    }

    let skippAppBarInComponent = false;
    if (!skipAppBarFlag && lessonPageContainerRef.current && lessonPageContainerRef.current.skipAppBar) {
      skippAppBarInComponent = lessonPageContainerRef.current.skipAppBar(lessonPlayDomain.domainData.currentPageIndex);
    }

    if (reinforcerStyle === REINFORCER_STYLE_KEY.simple) {
      // wait for the bottom bar to render
      await ObjectUtils.delay(50);
    }

    const result = {
      topBar: !skipAppBarFlag && !skippAppBarInComponent && lessonActivityTopBarRef.current ? [...lessonActivityTopBarRef.current.getEnableElements()] : null,
      bottomBar: !skipAppBarFlag && !skippAppBarInComponent && lessonActivityBottomBarRef.current ? [...lessonActivityBottomBarRef.current.getEnableElements()] : null,
    };

    skipAppBarFlagRef.current = null;

    const currentBody = lessonPageContainerRef.current ? await lessonPageContainerRef.current.getEnableElements(lessonPlayDomain.domainData.currentPageIndex) : null;
    if (currentBody) {
      result.body = currentBody;
    }
    updateElementsToScan(result);
    return result;
  };

  useEffect(() => {
    if (!switchAccessibility || isDialogShowing) {
      return;
    }

    getElementsForSwitchAccessFunction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogShowing, currentPageIndex]);

  useEffect(() => {
    if (!switchAccessibility) {
      return;
    }

    getElementsForSwitchAccessFunction({
      skipAppBarFlag: skipAppBarFlagRef.current,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMediaPlaying]);

  const updateSavedIndex = (currentSelectedIndex) => {
    savedIndex.current = currentSelectedIndex.current;
  };

  const resetSavedIndex = () => {
    savedIndex.current = -1;
  };

  const getElementsAfterCorrectAnswerSubmitted = async (currentSelectedIndex) => {
    const { lessonPagesData } = lessonPlayDomain.domainData;
    const currentPage = lessonPagesData[currentPageIndex];
    if (currentPage.activityType === ACTIVITY_TYPE_SORTING_ACTIVITY) {
      const result = await getElementsForSwitchAccessFunction();

      const { body } = result;
      const bodyNullSafe = body || [];

      // Only prompt card remain
      if (bodyNullSafe.length <= 1 || finishedActivityIndexes.current.includes(currentPageIndex)) {
        return;
      }
      let currentSelectedAnswerIndex = currentSelectedIndex.current;
      if (currentSelectedAnswerIndex >= body.length) {
        currentSelectedAnswerIndex = body.length;
      }
      ObjectUtils.setTimeout(() => {
        setContinueFromIndex(currentSelectedAnswerIndex);
      }, 100);
    } else if (currentPage.activityType === ACTIVITY_TYPE_SEQUENCING_ACTIVITY) {
      const result = await getElementsForSwitchAccessFunction();
      const { body, topBar } = result;
      const bodyNullSafe = body || [];
      const topBarNullSafe = topBar || [];

      // Only prompt card remain
      if (bodyNullSafe.length === 0 || finishedActivityIndexes.current.includes(currentPageIndex)) {
        return;
      }
      ObjectUtils.setTimeout(() => {
        setContinueFromIndex((bodyNullSafe.length / 2) + topBarNullSafe.length);
      }, 100);
    } else {
      getElementsForSwitchAccessFunction();
    }
  };

  const getElementsAfterIncorrectAnswerSubmitted = (currentSelectedIndex) => {
    const { lessonPagesData } = lessonPlayDomain.domainData;
    const currentPage = lessonPagesData[currentPageIndex];
    let scanParams;

    if (currentPage.activityType === ACTIVITY_TYPE_SORTING_ACTIVITY) {
      return;
    } else if (currentPage.activityType === ACTIVITY_TYPE_SEQUENCING_ACTIVITY) {
      const { topBar } = switchAccessElementDomain.domainData.elementsForSwitch;
      const nullSafeTopBar = topBar || [];
      ObjectUtils.setTimeout(() => {
        setContinueFromIndex(nullSafeTopBar.length);
      }, 100);
    } else if ([ACTIVITY_TYPE_BUILD_A_BOOK_SINGLE_DATA, ACTIVITY_TYPE_BUILD_A_BOOK_FACING_DATA, ACTIVITY_TYPE_GAME_ACTIVITY].includes(currentPage.activityType)) {
      // For matching and hotspots in the book activities
      const index = currentSelectedIndex.current;
      ObjectUtils.setTimeout(() => {
        setContinueFromIndex(index);
      }, 100);
      scanParams = {
        skipAppBarFlag: true,
      };
    } else if ([ACTIVITY_TYPE_MATCHING_ACTIVITY, ACTIVITY_TYPE_FLEXI_MATCHING_ACTIVITY].includes(currentPage.activityType)) {
      const index = currentSelectedIndex.current;
      ObjectUtils.setTimeout(() => {
        setContinueFromIndex(index);
      }, 100);
    }
    getElementsForSwitchAccessFunction(scanParams);
  };

  const resetElementsToScan = () => {
    updateElementsToScan([]);
  };

  return {
    lessonActivityTopBarRef,
    lessonActivityBottomBarRef,
    getElementsForSwitchAccessFunction: switchAccessibility ? getElementsForSwitchAccessFunction : () => { },
    updateSavedIndex: switchAccessibility ? updateSavedIndex : () => { },
    savedIndex: switchAccessibility ? savedIndex : () => { },
    resetSavedIndex: switchAccessibility ? resetSavedIndex : () => { },
    getElementsAfterIncorrectAnswerSubmitted: switchAccessibility ? getElementsAfterIncorrectAnswerSubmitted : () => { },
    getElementsAfterCorrectAnswerSubmitted: switchAccessibility ? getElementsAfterCorrectAnswerSubmitted : () => { },
    resetElementsToScan: switchAccessibility ? resetElementsToScan : () => { },
  };
};
