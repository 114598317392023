import { useEffect, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export const useGetSelectedStudentTable = ({
  selectedStudentIds,
  onDataChange,
  teacherStudentSelectorTableData,
}) => {
  const [internalData, setInternalData] = useState([...selectedStudentIds]);
  useEffect(() => {
    setInternalData(selectedStudentIds);
  }, [selectedStudentIds]);

  const tableConfig = [
    {
      title: '',
      align: 'center',
      width: '10%',
      dataField: 'isSelected',
      dataRenderer: (data) => {
        if (internalData.includes(data.id)) {
          return (
            <CheckBoxIcon
              className='vizzle-checkbox selectable'
              onClick={() => {
                const updatedData = internalData.filter((p) => p !== data.id);
                setInternalData(updatedData);
                onDataChange(updatedData);
              }}
            />
          );
        } else {
          return (
            <CheckBoxOutlineBlankIcon
              className='vizzle-checkbox selectable'
              onClick={() => {
                const updatedData = [
                  ...internalData,
                  data.id,
                ];
                setInternalData(updatedData);
                onDataChange(updatedData);
              }}
            />
          );
        }
      },
    },
    {
      title: 'Username',
      align: 'left',
      dataField: 'username',
      width: '36%',
    },
    {
      title: 'First Name',
      align: 'left',
      dataField: 'firstName',
      width: '32%',
    },
    {
      title: 'Last Name',
      align: 'left',
      dataField: 'lastName',
      width: '32%',
    },
  ];

  const tableData = teacherStudentSelectorTableData.map((data) => ({
    ...data,
    isSelected: internalData.includes(data.id),
  }));

  return {
    tableConfig,
    tableData,
  };
};
