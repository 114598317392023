/* eslint-disable no-undef */
let accessibeInit = false;
export const initAccessibe = ({ forTeacher } = { forTeacher: false }) => {
  const s = document.createElement('script');
  const h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';

  const position = forTeacher ? 'left' : 'right';

  s.async = true; s.onload = () => {
    acsbJS.init({
      statementLink: '',
      footerHtml: '',
      hideMobile: false,
      hideTrigger: false,
      disableBgProcess: false,
      language: 'en',
      position,
      leadColor: '#146FF8',
      triggerColor: '#146FF8',
      triggerRadius: '50%',
      triggerPositionX: position,
      triggerPositionY: 'bottom',
      triggerIcon: 'people',
      triggerSize: 'bottom',
      triggerOffsetX: 90,
      triggerOffsetY: 72,
      mobile: {
        triggerSize: 'small',
        triggerPositionX: position,
        triggerPositionY: 'bottom',
        triggerOffsetX: 90,
        triggerOffsetY: 72,
        triggerRadius: '20',
      },
    });
  }; h.appendChild(s);
  accessibeInit = true;
  // const s = document.createElement('script');
  // const e = !document.body ? document.querySelector('head') : document.body;
  // s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
  // s.async = true;
  // s.onload = () => {
  //   acsbJS.init({
  //     statementLink: '',
  //     footerHtml: '',
  //     hideMobile: true,
  //     hideTrigger: false,
  //     language: 'en',
  //     position: 'left',
  //     leadColor: '#146ff8',
  //     triggerColor: '#146ff8',
  //     triggerRadius: '50%',
  //     triggerPositionX: 'right',
  //     triggerPositionY: 'top',
  //     triggerIcon: 'people',
  //     triggerSize: 'small',
  //     triggerOffsetX: 20,
  //     triggerOffsetY: 20,
  //     mobile: {
  //       triggerSize: 'small',
  //       triggerPositionX: 'right',
  //       triggerPositionY: 'center',
  //       triggerOffsetX: 10,
  //       triggerOffsetY: 0,
  //       triggerRadius: '50%',
  //     },
  //   });
  // };
  // e.appendChild(s);
};

export const destroyAccessibe = () => {
  try {
    if (acsbJS) {
      acsbJS.destroy();
    }
  } catch (_e) {
    // ignore
  }
};

// eslint-disable-next-line no-unused-vars
export const showAccessibeChatIcon = () => {
  if (!accessibeInit) {
    return;
  }
  const acsbElement = document.querySelector('.acsb-trigger');
  if (acsbElement) {
    acsbElement.classList.remove('acsb-trigger-hide');
  }
};

// eslint-disable-next-line no-unused-vars
export const hideAccessibeChatIcon = () => {
  if (!accessibeInit) {
    return;
  }
  const acsbElement = document.querySelector('.acsb-trigger');
  if (acsbElement) {
    acsbElement.classList.add('acsb-trigger-hide');
  }
};

export const hideAccessbeChatIconInTimeInterval = () => {
  if (!accessibeInit) {
    return;
  }
  let i = 0;
  const t = setInterval(() => {
    const acsbElement = document.querySelector('.acsb-trigger');
    if (acsbElement) {
      hideAccessibeChatIcon();
      clearInterval(t);
    } else if (i > 2400) {
      // wait for 2 minute
      clearInterval(t);
    }

    i++;
  }, 50);
};
