import { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LessonActivityTopBar from '../lesson-bars/LessonActivityTopBar';
import LessonActivityBottomBar from '../lesson-bars/LessonActivityBottomBar';
import {
  useLessonActivityNavigator,
  useTopLessonActivityNavigator,
  usePrepareReinforcer,
  useGetRandomCelebration,
  useControlAccessibe,
  useHandleFfrPage,
} from './LessonActivityPlayHooks';
import {
  useGetFeedbackChat,
  useHandleChatWidget,
} from './LessonPlayFeedbackChatHooks';
import {
  useTrackingLessonStartAndEnd,
  useTrackingLessonResponse,
} from './LessonHistoryHooks';
import { useLessonPlay } from '../../context/LessonPlayContextProvider';
import LessonPagesContainer from './LessonPagesContainer';
import TransparentBlocker from '../../../../components/blocker/TransparentBlocker';
import './LessonActivityPlay.scss';
import Celebration from '../../../terminologies/celebration/Celebration';
import { initSwitchAccessDomains, useSwitchAccess } from '../../../switch-access/context/SwitchAccessContextProvider';
import { useShowSwitchAccessDialog, useRegisterSwitchKeys } from '../../../switch-access/SwitchAccessHooks';
import SwitchAccessDialog from '../../../switch-access/SwitchAccessDialog';
import { useGetElementsForSwitchAccess } from './LessonActivityPlayHooksForSwitchAccess';
import ObjectUtils from '../../../../utils/ObjectUtils';
import { ACTIVITY_TYPE_CATEGORIZING_ACTIVITY } from '../../../../AppConstants';
import { useHandleLessonResponseTracking } from './LessonResponseTrackingHooks';
import OverlayMaskLoader from '../../../../components/loaders/OverlayMaskLoader';
import { findFfrPage } from '../../../lessons/lessonmaintenance/hooks/LessonMaintenanceHooks';
import ChatWidget from '../../../../components/chat/ChatWidget';
import { isStudent } from '../../../../utils/User';
import ReinforcerV2 from '../../../terminologies/reinforcer-v2/ReinforcerV2';
import ReinforcerV2Service from '../../../terminologies/reinforcer-v2/ReinforcerV2Service';
import LessonCompletePage from '../ui/LessonCompletePage';
import { CELEBRATION_STYLE_KEY, REINFORCER_STYLE_KEY } from '../../../../constants/PlayPreferenceConstant';

/**
 * Play Lesson Action Container
 */
export default function LessonActivityPlay({
  onLessonFinished,
  createAdditionalComponents,
  showBottomLessonNavigator,
  onChatWidgetOpen,
}) {
  initSwitchAccessDomains();

  useControlAccessibe();
  const { switchAccessDomain } = useSwitchAccess();
  const { isDialogShowing } = switchAccessDomain.domainData;

  const {
    updateStudentUserProfile,
  } = useHandleFfrPage();

  // Track lesson
  const { isLessonCompleteRef, registeredLessonRef, studentHistoryId, handleLessonEnd } = useTrackingLessonStartAndEnd({
    onLessonEnd: updateStudentUserProfile,
  });
  const {
    startResponseEvent,
    insertCorrectResponse,
    insertIncorrectResponse,
  } = useTrackingLessonResponse(registeredLessonRef);

  const { lessonPlayDomain, lessonPlayChatDomain } = useLessonPlay();
  const {
    user,
    lessonPagesData,
    currentPageIndex,
    isMediaPlaying,
    userProfile,
    isPreviewMode,
    isPlayable,
    freeformAnswerData,
    lessonPagesDataSnapshot,
    lesson,
  } = lessonPlayDomain.domainData;

  const {
    showChatWidget,
  } = lessonPlayChatDomain.domainData;

  const { reinforcerStyle, reinforcerSound, celebrationStyle, celebrationSound } = userProfile;

  const {
    handleLessonTrackingCorrectAnswerSubmitted,
    handleLessonTrackingInCorrectAnswerSubmitted,
  } = useHandleLessonResponseTracking({
    registeredLessonRef,
    studentHistoryId,
  });

  const finishedActivityIndexes = useRef([]);

  const lessonPageContainerRef = useRef(null);

  const handleExit = async () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('start-lesson-finished');
    }
    onLessonFinished(isLessonCompleteRef.current);
  };

  // exit when complete the lesson
  const {
    showLessonCompletePage,
    setShowCorrectSubmissionMessage,
    ...lessonActivityBottomBar
  } = useLessonActivityNavigator({
    lessonPageContainerRef,
    handleExit,
    finishedActivityIndexes,
    isLessonCompleteRef,
    showBottomLessonNavigator,
    handleLessonEnd,
  });

  const {
    showExitButton,
  } = useTopLessonActivityNavigator();

  const userReinforcers = usePrepareReinforcer();

  const userCelebration = useGetRandomCelebration();

  const hasInstruction = lessonPagesData[currentPageIndex].instructions && lessonPagesData[currentPageIndex].activityType !== ACTIVITY_TYPE_CATEGORIZING_ACTIVITY;

  const { switchAccessibility } = userProfile;
  const { showSwitchAccessDialog } = useShowSwitchAccessDialog(switchAccessibility, hasInstruction);
  const {
    currentSelectedIndex,
    selectElementByIndex,
    setContinueFromIndex,
  } = useRegisterSwitchKeys(switchAccessibility);

  const {
    lessonActivityTopBarRef,
    lessonActivityBottomBarRef,
    getElementsForSwitchAccessFunction,
    updateSavedIndex,
    savedIndex,
    resetSavedIndex,
    getElementsAfterCorrectAnswerSubmitted,
    getElementsAfterIncorrectAnswerSubmitted,
    resetElementsToScan,
  } = useGetElementsForSwitchAccess({
    lessonPageContainerRef,
    setContinueFromIndex,
    finishedActivityIndexes,
  });

  const handleOnActivityFinished = async ({ toNextPage, pageIndex, alwaysGoToNext } = { toNextPage: true, pageIndex: undefined, alwaysGoToNext: false }) => {
    finishedActivityIndexes.current.push(pageIndex >= 0 ? pageIndex : currentPageIndex);
    if (toNextPage && (![REINFORCER_STYLE_KEY.simple].includes(reinforcerStyle) || alwaysGoToNext)) {
      lessonActivityBottomBar.onNext();
      return;
    }

    const currentFreeformAnswerDataIndex = freeformAnswerData && freeformAnswerData.findIndex((ffad) => ffad.attributes.pageNumber === currentPageIndex + 1);

    if (freeformAnswerData && freeformAnswerData.length > 0) {
      const lessonPageData = lessonPagesDataSnapshot.pages[currentPageIndex];
      const isFfrPage = findFfrPage({ pages: [lessonPageData] });
      if (!isFfrPage) {
        return;
      }
      if (currentPageIndex === lessonPagesData.length - 1) {
        // last page
        lessonActivityBottomBar.onNext();
      } else if (currentFreeformAnswerDataIndex === freeformAnswerData.length - 1) {
        isLessonCompleteRef.current = true;
        handleLessonEnd();
        handleExit();
      } else {
        let nextFreeformAnswerData = null;
        for (let i = currentFreeformAnswerDataIndex + 1; i < freeformAnswerData.length; i++) {
          const data = freeformAnswerData[i];
          if (data.attributes.comments) {
            nextFreeformAnswerData = data;
            break;
          }
        }
        if (nextFreeformAnswerData) {
          lessonPageContainerRef.current.getSliderRef().slickGoTo(nextFreeformAnswerData.attributes.pageNumber - 1);
        } else {
          isLessonCompleteRef.current = true;
          handleLessonEnd();
          handleExit();
        }
      }
    }
  };

  const handleOnCorrectAnswerSelected = async ({
    insertResponse,
    responseIndex,
    once,
    isFinished,
    showReinforcer,
    autoCloseCorrectSubmissionMessage,
  } = {
    insertResponse: true,
    responseIndex: undefined,
    once: false,
    isFinished: false,
    showReinforcer: true,
    autoCloseCorrectSubmissionMessage: undefined,
  }) => {
    resetElementsToScan();
    if (showReinforcer) {
      if ([REINFORCER_STYLE_KEY.animated].includes(reinforcerStyle)) {
        await ReinforcerV2Service.playRandomReinforcer(userReinforcers);
      } else if ([REINFORCER_STYLE_KEY.simple].includes(reinforcerStyle)) {
        setShowCorrectSubmissionMessage(true);
        if (autoCloseCorrectSubmissionMessage) {
          await ObjectUtils.setTimeout(() => {
            setShowCorrectSubmissionMessage(false);
          }, autoCloseCorrectSubmissionMessage);
        }
      }
    }
    if (insertResponse) {
      insertCorrectResponse(responseIndex, once, isFinished);
    }
    handleLessonTrackingCorrectAnswerSubmitted({
      responseIndex,
    });
    getElementsAfterCorrectAnswerSubmitted(currentSelectedIndex);
    return Promise.resolve();
  };

  const handleOnIncorrectAnswerSelected = (responseIndex, once = false, isFinished = false) => {
    insertIncorrectResponse(responseIndex, once, isFinished);
    handleLessonTrackingInCorrectAnswerSubmitted({
      responseIndex,
      once,
    });
    getElementsAfterIncorrectAnswerSubmitted(currentSelectedIndex);
  };

  const handleInteractionOpen = (isOpen) => {
    if (isOpen) {
      startResponseEvent();
    }
    getElementsForSwitchAccessFunction({ skipAppBarFlag: isOpen });
    if (isOpen) {
      updateSavedIndex(currentSelectedIndex);
    } else {
      // wait for the popup to close
      ObjectUtils.setTimeout(() => {
        selectElementByIndex(savedIndex.current);
        resetSavedIndex();
      }, 100);
    }
  };

  const handleTriggerSwitchScan = async ({ continueIndex } = { continueIndex: false }) => {
    if (continueIndex) {
      updateSavedIndex(currentSelectedIndex);
    }

    await getElementsForSwitchAccessFunction();
    if (continueIndex) {
      ObjectUtils.setTimeout(() => {
        selectElementByIndex(savedIndex.current);
        resetSavedIndex();
      }, 100);
    }
  };

  const handleOnInstructionFinished = () => {
    const hasDialogShown = showSwitchAccessDialog();
    if (hasDialogShown) {
      getElementsForSwitchAccessFunction();
    }
  };

  const {
    chatOpen,
    handleOnChatOpen,
    dataRecords,
    handleOnChatSubmit,
    showBadge,
  } = useHandleChatWidget({ onChatWidgetOpen, studentHistoryId });

  const {
    getFeedbackChat,
  } = useGetFeedbackChat({ studentHistoryId });

  useEffect(() => {
    if (isStudent(user) && studentHistoryId) {
      getFeedbackChat({
        showChatWidget: userProfile.feedbackChat,
        studentId: user.id,
        lessonId: lesson.id,
        hideWidgetWhenNoFeedbackChat: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentHistoryId]);

  const lesonPlaySlider = useMemo(() => (
    <LessonPagesContainer
      ref={lessonPageContainerRef}
      onActivityFinished={handleOnActivityFinished}
      onCorrectAnswerSelected={handleOnCorrectAnswerSelected}
      onIncorrectAnswerSelected={handleOnIncorrectAnswerSelected}
      onInteractionOpen={handleInteractionOpen}
      onInstructionFinished={handleOnInstructionFinished}
      onTriggerSwitchScan={handleTriggerSwitchScan}
      registeredLessonRef={registeredLessonRef}
      studentHistoryId={studentHistoryId}
    />
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [lessonPagesData, currentPageIndex, studentHistoryId]);

  const reinforcer = useMemo(() => {
    if (reinforcerStyle === REINFORCER_STYLE_KEY.animated) {
      return (
        <ReinforcerV2 reinforcerList={userReinforcers} enableAudio={reinforcerSound} />
      );
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Fade in>
        <Box
          className='lesson-activity-play'
          onMouseDown={() => {
            // setShowCorrectSubmissionMessage(false);
          }}
        >
          <LessonActivityTopBar
            onExit={handleExit} // exit lesson
            currentPage={currentPageIndex + 1}
            totalPage={lessonPagesData.length}
            ref={lessonActivityTopBarRef}
            showExitButton={showExitButton}
            createAdditionalComponents={createAdditionalComponents}
          />

          {!lessonActivityBottomBar.showCelebration && !showLessonCompletePage && (
            <div className='content'>
              {lesonPlaySlider}
            </div>
          )}

          <LessonCompletePage
            enableSound={celebrationStyle === CELEBRATION_STYLE_KEY.simple && celebrationSound}
            open={showLessonCompletePage}
            onClose={() => {
              handleLessonEnd();
              handleExit();
            }}
          />

          {userCelebration && (
            <Celebration
              celebrationData={userCelebration.celebrationData}
              onFinish={handleExit}
              show={lessonActivityBottomBar.showCelebration}
              enableAudio={celebrationSound}
            />
          )}

          <LessonActivityBottomBar {...lessonActivityBottomBar} ref={lessonActivityBottomBarRef} />
        </Box>
      </Fade>
      {reinforcer}
      <TransparentBlocker show={isMediaPlaying} isFullScreen />
      <SwitchAccessDialog open={isDialogShowing} />
      <OverlayMaskLoader show={user && user.id && !isPreviewMode && isPlayable && !studentHistoryId} blockedScreen loadingMessage='Loading data...' />
      {showChatWidget && !lessonActivityBottomBar.showCelebration && (
        <ChatWidget
          open={chatOpen}
          onOpen={handleOnChatOpen}
          title='Chat'
          dataRecords={dataRecords}
          onSubmit={handleOnChatSubmit}
          showBadge={showBadge}
        />
      )}
    </>
  );
}

LessonActivityPlay.defaultProps = {
  onLessonFinished: () => { },
  createAdditionalComponents: () => null,
  showBottomLessonNavigator: false,
  onChatWidgetOpen: () => { },
};

LessonActivityPlay.propTypes = {
  onLessonFinished: PropTypes.func,
  createAdditionalComponents: PropTypes.func,
  showBottomLessonNavigator: PropTypes.bool,
  onChatWidgetOpen: PropTypes.func,
};
