import Logger from '../utils/Logger';
import vizzleAxios, { vizzleAxiosWithRetry, vizzleLessonAzureTrackingAxios } from './service';

// eslint-disable-next-line import/prefer-default-export
export const updateLessonPlayInfo = async (operation, lessonPlayId, data) => {
  const url = `/reporting/studentLessonPlays/${lessonPlayId}?operation=${operation}`;
  const result = await vizzleAxios.post(url, data);
  return result;
};

export const submitLessonEvent = async (lessonEvent) => {
  const url = '/lessonplay/detail/lesson/delimiter';
  const result = await vizzleAxiosWithRetry.post(url, lessonEvent);
  return result;
};

export const submitLessonResponseEvent = async (lessonEvent) => {
  const url = '/lessonplay/detail/response';
  const result = await vizzleAxiosWithRetry.post(url, lessonEvent);
  return result;
};

export const getStudentPlayHistory = async ({ lessonPlayId }) => {
  const url = `/users/history/${lessonPlayId}`;
  const result = await vizzleAxiosWithRetry.get(url);
  return result.data;
};

export const saveLessonPlaySnapshot = async ({ studentHistoryId, lessonPagesDataSnapshot }) => {
  const url = '/lesson';
  const payload = {
    studentHistoryId,
    lesson: {
      pages: lessonPagesDataSnapshot.pages,
    },
    updatedAt: lessonPagesDataSnapshot.updatedAt,
  };

  try {
    await vizzleLessonAzureTrackingAxios.post(url, payload);
  } catch (e) {
    Logger.logError(e);
    Logger.logWhenDebugModeIsOn(`Save lesson with history id ${studentHistoryId} to backup storage`);
    vizzleAxios
      .post('/failed/lessons', {
        ...payload,
        reason: e.message,
      })
      .catch((error) => {
        Logger.logError(error);
      });
  }
};

export const trackAnswer = async (trackAnswerList) => {
  const url = '/lesson/answer';
  const payload = { answers: trackAnswerList };

  try {
    // Only call Azure app function when the data is stored in AWS successfully
    await vizzleLessonAzureTrackingAxios.post(url, payload);
  } catch (e) {
    Logger.logError(e);
    Logger.logWhenDebugModeIsOn('Save data to backup storage');
    vizzleAxios
      .post('/failed/answers', {
        ...payload,
        reason: e.message,
      })
      .catch((error) => {
        Logger.logError(error);
      });
  }
};

export const getLessonPlaySnapshot = async ({ studentHistoryId }) => {
  const url = `/lesson/${studentHistoryId}`;
  const result = await vizzleLessonAzureTrackingAxios.get(url);
  return result.data;
};

export const getAnswerFreeForm = async ({ studentHistoryId }) => {
  const url = `/users/answers/freeform/${studentHistoryId}`;
  const result = await vizzleAxios.get(url);
  return result.data;
};

export const analyticsAnswerFreeForm = async ({ studentId, studentHistoryId }) => {
  const url = `/users/${studentId}/view/lessonplay/${studentHistoryId}`;
  const result = await vizzleAxios.post(url, {});
  return result.data;
};

export const getLessonPlayStatus = async (lessonId) => {
  const url = `/users/lessons/${lessonId}/play/status/current`;
  const result = await vizzleAxios.get(url);
  return result.data;
};
