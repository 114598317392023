import SettingSelectorForm from './form/SettingSelectorForm';
import { RED_X_PROMPT_OPTIONS, MATCHING_TYPE_OPTIONS, MATCHING_MAX_CHOICES_OPTIONS } from '../../../../constants/PlayPreferenceConstant';
import { redXPromptingTooltipText, matchingTooltipText, maxMatchingChoicesTooltipText } from '../../../../texts/TooltipText';

const InteractionOptions = () => (
  <>
    <SettingSelectorForm
      label='Red X Prompting'
      options={RED_X_PROMPT_OPTIONS}
      attributeNameToSave='prompt'
      tooltip={redXPromptingTooltipText}
    />
    <SettingSelectorForm
      label='Matching Type'
      options={MATCHING_TYPE_OPTIONS}
      attributeNameToSave='interactionType'
      tooltip={matchingTooltipText}
    />
    <SettingSelectorForm
      label='Max Choices'
      options={MATCHING_MAX_CHOICES_OPTIONS}
      attributeNameToSave='maxMatchingChoices'
      tooltip={maxMatchingChoicesTooltipText}
    />
  </>
);

export default InteractionOptions;
