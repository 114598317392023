import { useEffect, useRef } from 'react';
import { Box, Button } from '@mui/material';
import { Howl } from 'howler';
import PropTypes from 'prop-types';
import trophyAudio from '../../../../static/sounds/trophy.mp3';
import './LessonCompletePage.scss';
import SimpleCelebration from '../../../settings/celebrations/simple-celebration/SimpleCelebration';

export default function LessonCompletePage({ displayCloseButton, onClose, open, enableSound }) {
  const soundRef = useRef();
  useEffect(() => {
    if (enableSound) {
      // eslint-disable-next-line no-unused-vars
      soundRef.current = new Howl({
        src: trophyAudio,
        preload: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (soundRef.current && open) {
      soundRef.current.play();
    }
  }, [open]);

  if (!open) {
    return null;
  }
  return (
    <div className='lesson-complete-page'>
      <Box width={320} mb={4}>
        <SimpleCelebration />
      </Box>

      {displayCloseButton && (
        <Button className='btn-blue-primary close-button' onClick={onClose}>
          Close
        </Button>
      )}
    </div>
  );
}

LessonCompletePage.defaultProps = {
  displayCloseButton: true,
  onClose: () => { },
  open: false,
  enableSound: false,
};

LessonCompletePage.propTypes = {
  displayCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  enableSound: PropTypes.bool,
};
