export const SEASONAL_FILTER_OPTIONS = [
  { key: 'fall', name: 'Fall', value: 6 },
  { key: 'spring', name: 'Spring', value: 5 },
  { key: 'stPat', name: 'Saint Patricks Day', value: 4 },
  { key: 'valentine', name: 'Valentines Day', value: 3 },
  { key: 'winter', name: 'Winter', value: 2 },
  { key: 'halloween', name: 'Halloween', value: 1 },
  { key: 'original', name: 'Original', value: 0 },
];

export const THEME_OPTIONS = [
  { key: 'selfSelect', name: 'Self-Select', value: 0 },
  { key: 'castle', name: 'Castle', value: 1 },
  { key: 'space', name: 'Space', value: 2 },
  { key: 'neutral', name: 'Neutral', value: 3 },
  { key: 'football', name: 'Football', value: 4 },
  { key: 'camp', name: 'Camp', value: 5 },
  { key: 'treehouse', name: 'Treehouse', value: 6 },
  { key: 'retrofuture', name: 'Retrofuture', value: 7 },
];

export const RED_X_PROMPT_OPTIONS = [
  { key: 'on', name: 'ON', value: true },
  { key: 'off', name: 'OFF', value: false },
];

export const MATCHING_TYPE_OPTIONS = [
  { key: 'dragAndDrop', name: 'DRAG & DROP', value: 2 },
  { key: 'oneClick', name: '1-CLICK (AUTO-PLAY)', value: 1 },
  { key: 'clickToSubmit', name: '2-CLICK', value: 3 },
];

export const MATCHING_MAX_CHOICES_OPTIONS = [
  { key: 'default', name: 'Default', value: 0 },
  { key: '1', name: '1', value: 1 },
  { key: '2', name: '2', value: 2 },
  { key: '3', name: '3', value: 3 },
  { key: '4', name: '4', value: 4 },
  { key: '5', name: '5', value: 5 },
];

export const TTS_OPTIONS = [
  { key: 'on', name: 'ON', value: true },
  { key: 'off', name: 'OFF', value: false },
];

export const STUDENT_SETTINGS_OPTIONS = [
  { key: 'on', name: 'ON', value: true },
  { key: 'off', name: 'OFF', value: false },
];

export const REINFORCERS_OPTIONS = [{ key: 'reinforcers', name: 'Set All Reinforcers to Off', value: 0 }];

export const CELEBRATIONS_OPTIONS = [{ key: 'celebrations', name: 'Set All Celebrations to Off', value: [0] }];

export const REINFORCER_STYLE_KEY = {
  animated: 0,
  simple: 1,
  none: 2,
};

export const CELEBRATION_STYLE_KEY = {
  interactive: 0,
  simple: 1,
  none: 2,
};

export const PLAY_MODE = {
  selfSelected: 0,
  progressBasedPlay: 1,
};

const messages = [
  'Correct!',
  'Well done!',
  'Nice work!',
  'Excellent!',
  'Marvelous!',
  'Great job!',
  'Fantastic!',
  'Superb!',
  'Tremendous!',
  'Awesome!',
  'Yes!',
];

export const getRandomMessage = () => messages[Math.floor(Math.random() * messages.length)];
