/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState } from 'react';
import {
  Box,
  Button,
  Link,
  MenuItem,
  Menu,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDomain } from '../../../states/AppDomainProvider';
import './TeacherLogoutDropdown.scss';
import CustomDialog from '../../../components/dialog/CustomDialog';
import { useLogout } from '../../logout/LogoutHooks';
import LogoutConfirmation from '../../logout/LogoutConfirmation';
import { URL_GETTING_STARTED, URL_MY_SETTINGS } from '../../../routes/RouteConstants';
import { isTexasTeacher } from '../../../utils/User';
import texasIcon from '../../../static/images/texas_icon.svg';

const videoUrl = 'https://assets.monarchteachtech.com/in-app-help-videos/Non-Texas/New%20Vizzle%20Excel%20Tour.mp4';

const TeacherLogoutDropdown = () => {
  const { userDomain } = useDomain();
  const { user, switchToNewVizzle } = userDomain.domainData;

  const anchorRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const handleToggle = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    logoutDialog,
    openLogoutDialog,
    closeLogoutDialog,
  } = useLogout();

  return (
    <>
      <Box className='teacher-logout-dropdown' data-test='teacher-logout-dropdown'>
        <div>
          <Button
            ref={anchorRef}
            onClick={handleToggle}
          >
            <MenuItem className='logout-link' data-private>
              Welcome {user.firstName}
              <ExpandMoreIcon />
            </MenuItem>
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PopoverClasses={{
              paper: 'teacher-logout-menu-list-popover',
            }}
          >
            <MenuItem
              data-test='getting-started'
              onClick={() => {
                handleClose();
                navigate(URL_GETTING_STARTED);
              }}
            >
              Getting Started
            </MenuItem>
            <MenuItem
              data-test='teacher-setting-menu'
              onClick={() => {
                handleClose();
                navigate(URL_MY_SETTINGS);
              }}
            >
              Teacher Settings
            </MenuItem>
            <MenuItem
              data-test='training-guide-link'
              onClick={() => {
                handleClose();
                window.open('https://support.govizzle.com/vizzle-training-guides-with-video-how-tos', '_blank');
              }}
            >
              Training Guides
            </MenuItem>
            {isTexasTeacher(user) && (
              <MenuItem
                data-test='texas-resources'
                onClick={() => {
                  handleClose();
                  window.open('https://get.rethinked.com/texas_resources_vizzle', '_blank');
                }}
              >
                <Box display='flex' alignItems='center' gap={1}>
                  Texas Resources
                  <img src={texasIcon} alt='Texas icon' />
                </Box>
              </MenuItem>
            )}
            {!user.hideToolbar && (
              <MenuItem
                data-test='exit-vizzle'
                onClick={() => {
                  handleClose();
                  openLogoutDialog();
                }}
              >
                {switchToNewVizzle ? 'Switch to Classic Vizzle' : 'Exit'}
              </MenuItem>
            )}
          </Menu>
        </div>
        {switchToNewVizzle && (
          <Link
            data-test='see-whats-new'
            component='button'
            variant='body2'
            onClick={() => {
              setOpenDialog(true);
            }}
            className='see-whats-new'
          >
            See what&apos;s new
          </Link>
        )}
      </Box>
      <CustomDialog
        openDialog={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        displayCloseIcon
        className='see-whats-news-dialog-container'
        content={(
          <Box width='820px' display='flex' flexDirection='row' alignItems='flex-start' justifyContent='center'>
            <video
              controls
              alt="What' s new."
              title="What's new."
              height='auto'
              playsInline
              src={videoUrl}
              width='90%'
            >
              <track
                src={videoUrl}
                kind='captions'
                label='english_captions'
              />
            </video>
          </Box>
        )}
      />
      <LogoutConfirmation
        title={switchToNewVizzle ? 'Switch to Classic Vizzle?' : undefined}
        message={switchToNewVizzle ? (
          <div>
            Are you sure you want to switch to Classic Vizzle?
            <br />
            This will log you out.
          </div>
        ) : undefined}
        open={logoutDialog}
        onConfirm={() => {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage('exit-confirm');
          }
          if (window.parent) {
            window.parent.postMessage('exit-confirm', '*');
          }
        }}
        onClose={closeLogoutDialog}
      />
    </>
  );
};

export default TeacherLogoutDropdown;
