import PropTypes from 'prop-types';
import SearchAndSortableTable from '../../../../../../../components/table/SearchAndSortableTable';
import { useGetSelectedStudentTable } from './StudentTableSelectorHooks';
import './StudentTableSelector.scss';

const StudentTableSelector = ({
  onDataChange,
  selectedStudentIds,
  teacherStudentSelectorTableData,
}) => {
  const {
    tableConfig,
    tableData,
  } = useGetSelectedStudentTable({
    selectedStudentIds,
    onDataChange,
    teacherStudentSelectorTableData,
  });

  return (
    <div className='student-table-selector'>
      <SearchAndSortableTable
        containerClassName='student-selector-table'
        config={tableConfig}
        tableData={tableData}
        searchPlaceholder='Search your students'
        initTableState={{
          order: 'desc',
          orderBy: 'username',
        }}
        searchFunction={(keyword, data) => {
          const name = `${data.firstName} ${data.lastName}`;
          const isNameMatched = name.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
          const isEmailMatched = data.username.toLowerCase().indexOf(keyword.toLowerCase()) >= 0;
          return isNameMatched || isEmailMatched;
        }}
        autoResetTableState={false}
      />
    </div>
  );
};

StudentTableSelector.defaultProps = {
  onDataChange: () => null,
  teacherStudentSelectorTableData: {},
  selectedStudentIds: [],
};

StudentTableSelector.propTypes = {
  teacherStudentSelectorTableData: PropTypes.array,
  onDataChange: PropTypes.func,
  selectedStudentIds: PropTypes.array,
};

export default StudentTableSelector;
