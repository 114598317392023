import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Toolbar,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { isMobile } from '../../../../utils/DeviceUtils';
import './LessonActivityBottomBar.scss';
import { useScreenReader } from '../../../screen-reader/ScreenReaderHooks';
import { useRegisterSwitchAccessScanEvent } from '../../../switch-access/SwitchAccessHooks';
import { TOOLBAR_ACTIONS_ID, TOOLBAR_NEXT_ACTION_ID } from '../../../../components/navigator/LessonNavigator';

/**
 * Play Lesson Action Container
 */
const LessonActivityBottomBar = forwardRef(({
  onNext,
  onPrevious,
  hideNext,
  hidePrevious,
  children,
  showCorrectSubmissionMessage,
}, ref) => {
  const previousButtonRef = useRef(null);
  const nextButtonRef = useRef(null);

  const {
    screenReaderOn,
    readWhenScreenReaderIsOn,
  } = useScreenReader();

  useImperativeHandle(ref, () => ({
    getEnableElements: () => {
      const result = [];
      if (previousButtonRef.current) {
        result.push(previousButtonRef.current);
      }
      if (nextButtonRef.current) {
        result.push(nextButtonRef.current);
      }
      return result;
    },
  }));

  useRegisterSwitchAccessScanEvent(previousButtonRef, () => {
    readWhenScreenReaderIsOn(previousButtonRef.current);
  });

  useRegisterSwitchAccessScanEvent(nextButtonRef, () => {
    readWhenScreenReaderIsOn(nextButtonRef.current);
  });

  return (
    <div className='lesson-activity-bottom-bar'>
      <Toolbar className={`lesson-activity-toolbar ${showCorrectSubmissionMessage ? 'success-message-toolbar' : ''}`}>
        {hidePrevious ? (<div className='navigation-button-empty' />) : (
          <Button
            className={`navigation-button ${isMobile() ? 'navigation-button-mobile' : ''}`}
            disableRipple
            disableElevation
            disableFocusRipple
            onClick={onPrevious}
            ref={previousButtonRef}
            alt='Go Backward'
            data-switch-access-scan={screenReaderOn ? 'true' : 'false'}
            data-test='lesson-play-previous-action'
          >
            <ArrowBackIcon className='icon' />
          </Button>
        )}
        {children || <div />}
        <div className='next-toolbar-container'>
          <div className='hide' id={TOOLBAR_ACTIONS_ID} />
          {hideNext ? (<div className='navigation-button-empty' id={TOOLBAR_NEXT_ACTION_ID} />) : (
            <Button
              id={TOOLBAR_NEXT_ACTION_ID}
              className={`navigation-button right ${isMobile() ? 'navigation-button-mobile' : ''}`}
              disableRipple
              disableElevation
              disableFocusRipple
              onClick={onNext}
              ref={nextButtonRef}
              alt='Go Forward'
              data-switch-access-scan={screenReaderOn ? 'true' : 'false'}
              data-test='lesson-play-next-action'
            >
              <ArrowForwardIcon className='icon' />
            </Button>
          )}
        </div>
      </Toolbar>
    </div>
  );
});

export default LessonActivityBottomBar;

LessonActivityBottomBar.defaultProps = {
  onNext: () => { },
  onPrevious: () => { },
  hideNext: true,
  hidePrevious: true,
  children: null,
  showCorrectSubmissionMessage: false,
};

LessonActivityBottomBar.propTypes = {
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  hideNext: PropTypes.bool,
  hidePrevious: PropTypes.bool,
  children: PropTypes.node,
  showCorrectSubmissionMessage: PropTypes.node,
};
