import { useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Portal } from '@mui/material';
import Button from '@mui/material/Button';
import './MicrosoftCopilot.scss';

export const showMicrosoftCopilot = (show) => {
  const element = document.querySelector('.ms-copilot');
  if (!element) {
    return;
  }
  if (show) {
    element.classList.remove('hide');
  } else {
    element.classList.add('hide');
  }
};

export default function MicrosoftCopilot() {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef();
  const handleClick = () => {
    setOpen(!open);
  };

  const id = open ? 'chat-popover' : undefined;

  return (
    <Portal container={document.body}>
      <Box className='ms-copilot'>
        {!open && (
          <Button
            aria-describedby={id}
            variant='contained'
            onClick={handleClick}
            className='btn-primary chat-button'
            ref={buttonRef}
          >
            Chat
          </Button>
        )}
        {open && (
          <div className='copilot-popup-container'>
            <IconButton
              data-test='close-button'
              aria-label='close'
              className='close-button'
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
            <iframe
              title='iframe'
              src='https://copilotstudio.microsoft.com/environments/Default-d273efcb-f05e-4f14-bdbe-8689d559bc0a/bots/cr69b_vizzleSupportChatbot/webchat?__version__=2'
              style={{
                border: 0,
                width: '100%',
                height: '80vh',
              }}
            />
          </div>
        )}
      </Box>
    </Portal>
  );
}
