import { Typography } from '@mui/material';

export const submitToVizzleLibraryTooltipText = {
  title: 'Share with the World',
  messages: [
    'This will make a copy of your Activity and send it to the Vizzle team for review.',
    'If approved, the new copy will be available to all in the Vizzle Library.  You retain your own private version.',
  ],
};

export const lessonLevelTooltipText = {
  title: 'Activity Level',
  messages: [
    'Level 1: Higher level text and comprehension activities without visual cues',
    'Level 2: Modified text, visual cues',
    'Level 3: Minimal text, visual cues',
  ],
};

export const matchingQuestionKeyTooltipText = {
  title: 'What is the Key for?',
  messages: [
    ' - Keys determine correct and incorrect answers',
    ' - The first answer key automatically matches the question key',
    ' - Multiple correct answers are allowed',
    ' - Use brief text for the key (e.g "3" or "main character")',
  ],
  videoUrl: 'https://assets.monarchteachtech.com/in-app-help-videos/in-app-help-videos/Explanation%20of%20Keys.mp4',
};

export const redXPromptingTooltipText = {
  title: 'Red X Prompting',
  messages: [
    'Displays a Red X when the user submits an incorrect answer.',
  ],
};

export const matchingTooltipText = {
  title: 'Matching Type',
  messages: [
    'Drag & Drop requires the user to drag-and-drop answers in a Matching Activity.',
    '1-Click (Auto-Play) requires the user to click on an answer in order to submit.',
    'Each book and matching page is presented in an animated sequence with auto-play of any audio and video.',
    '2-Click requires the user to click the answer first, then click the question to submit the answer.',
  ],
};

export const maxMatchingChoicesTooltipText = {
  title: 'Max Choices',
  messages: [
    'Changing the field size reduces (never increases) the maximum number of choices for each Matching Activity or Pop-up Quiz.',
    'The default setting reflects how the activity was originally created.',
  ],
};

export const guideToLevelingTooltipText = {
  title: 'Guide to Leveling',
  messages: ['Text modifications will decrease as the level increases, and visual cues will increase as the level decreases.',
    'Level 1: Higher level text and comprehension activities without visual cues',
    'Level 2: Modified text, visual cues',
    'Level 3: Minimal text, visual cues',
  ],
};

export const guideSwitchKeyboardAccessibility = {
  title: 'Switch',
  messages: [
    'Switch Accessibility allows activity interaction via two-switch access or a keyboard.',
    'Please note that keyboard interaction is space + enter (as opposed to tab + enter).',
  ],
};

export const guideExitLesson = {
  title: 'Exit Activity',
  messages: [
    'Provide a student with an \'Exit Activity\' button allowing them to exit a activity before completion.',
  ],
};

export const studentGoalAndObjectiveTag = {
  title: 'Goal & Objective Tags',
  messages: [
    'Tagging activities is a great way to align activities to specific areas of a student\'s IEP.',
    'Student activity can then be viewed by goal and objective tags on the student\'s Data Tracking page.',
    'We recommend using a skill for the tag name (e.g., “time to hour”, “letter ID”) for ease of use.',
  ],
};

export const completeReviewTooltipText = {
  title: 'CompleteReview',
  messages: [
    'This will return the activity to the activity creator.',
  ],
};

export const shareWithReviewerTooltipText = {
  title: 'Share with Reviewer',
  messages: [
    'If you are working on Units, then this button is used to make your activity available to the Vizzle team to review.',
    'Once any necessary edits are made, then the activities can be submitted via "Share with the World!" .',
  ],
};

export const schoologyTooltipText = {
  title: 'Schoology ID',
  messages: [
    'If your district uses Schoology LMS, enter the Schoology ID here.',
  ],
};

// export const teacherNonSharingStudentTooltipText = {
//   title: 'Add Student',
//   messages: [
//     'Student rosters are not visible for your account.',
//     'In this mode, you must create your own students.',
//     'As students are created, they will be added to your roster.',
//   ],
// };

export const playExpiredLessonTooltipText = {
  title: 'Play Expired Activities',
  messages: [
    'In the case where the student has no currently assigned activities, ',
    'the last 10 assigned activities will be made available for the student to work on.',
  ],
};

export const feedbackLoopTooltipText = {
  title: 'Click here to watch the video',
  messages: [],
  videoUrl: 'https://assets.monarchteachtech.com/in-app-help-videos/in-app-help-videos/ff%20help%20video.mp4',
};

export const searchBoxTooltipText = {
  title: 'Search',
  messages: ['To search by a phrase try enclosing your search terms in double quotes'],
};

export const singlePlayTooltipText = {
  title: 'Single/Multiple Play',
  messages: [
    'When Single Play is enabled, the activity is available until it is completed one time; the activity will not be available again until it is reassigned.',
    'When Multiple Play is enabled, the activity can be played multiple times during the assignment period.',
  ],
};

export const updateCredentialTooltipText = {
  title: 'Updating Credential',
  messages: [
    'If you update any of the following credentials in Vizzle, these changes will also be applied to',
    'your centralized login account which allows you access any Rethink Product that you belong',
    <Typography>to: <strong>First Name</strong>, <strong>Last Name</strong>, <strong>Username</strong>, <strong>Password</strong>.</Typography>,
  ],
};

export const updateCredentialAllStudentsTooltipText = {
  title: 'Updating Credential',
  messages: [
    'Updating passwords for all students in you Vizzle roster will also be applied to their',
    'centralized login accont wich allows them to access any Rethink product that',
    'they belong to.',
  ],
};

export const stateIdentifierTooltipText = {
  title: 'State Identifier',
  messages: [
    <Typography>The state identifier should be the <strong>unique</strong></Typography>,
    <Typography><strong>student state ID</strong> that is issued to the</Typography>,
    'student by the school district. This ensures',
    'that the student data collected will be',
    'accessible across Rethink platforms',
    'regardless of username and/or student',
    'name.',
  ],
};

export const studentSettingsTooltipText = {
  title: 'Student Settings',
  messages: [
    'When set to OFF, the settings button will be disabled for this student the next time they log into Vizzle.',
    'This means they will not have the ability to customize their own themes, reinforcers, and text-to-speech voice settings.',
  ],
};

export const studentSettingsAllStudentsTooltipText = {
  title: 'Student Settings',
  messages: [
    'When set to OFF, the settings button will be disabled for all of your students the next time they log into Vizzle.',
    'This means they will not have the ability to customize their own themes, reinforcers, and text-to-speech voice settings.',
  ],
};
