import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import ScreenReaderSettings from './ScreenReaderSettings';
import SwitchKeyboardSettings from './SwitchKeyboardSettings';
import TextToSpeechSettings from './TextToSpeechSettings';
import TooltipWithIndicator from '../../../../../components/tooltip/TooltipWithIndicator';
import { guideSwitchKeyboardAccessibility, studentSettingsTooltipText } from '../../../../../texts/TooltipText';
import AccessibeSettings from './AccessibeSettings';
import './Accessibility.scss';
import StudentSettings from './StudentSettings';

function getComponent(entry, key) {
  return (
    <Box
      p={1}
      paddingRight={1}
      key={key}
      mb='20px'
      data-test={`accessibility-${entry.title}`}
    // height={90}
    >
      <Box display='flex' mr='15px' alignItems='center'>
        <Typography variant='subtitle1' fontWeight={500} className='nested'>
          {entry.title}
        </Typography>
        {entry.tooltip}
      </Box>
      {entry.component}
    </Box>
  );
}

const AccessibilitySettings = () => {
  const components = [
    {
      title: 'Text to Speech',
      component: <TextToSpeechSettings />,
    },
    {
      title: 'Switch/Keyboard Accessibility',
      component: <SwitchKeyboardSettings />,
      tooltip: <TooltipWithIndicator className='tooltip switch-tooltip' {...guideSwitchKeyboardAccessibility} />,
    },
    {
      title: 'JAWS® or other Screen Reader',
      component: <ScreenReaderSettings />,
    },
    {
      title: 'Display accessiBe Widget',
      component: <AccessibeSettings />,
    },
    {
      title: 'Student Settings',
      component: <StudentSettings />,
      tooltip: <TooltipWithIndicator className='tooltip switch-tooltip' {...studentSettingsTooltipText} />,
    },
  ];

  return (
    <div className='accessibility-component'>
      <div className='accessibility-component-tts'>
        {getComponent(components[0], 'student-setting-entry-tts')}
      </div>
      <div className='accessibility-components'>
        {components.splice(1).map((entry, index) => (
          getComponent(entry, `student-setting-entry-${index}`)
        ))}
      </div>
    </div>
  );
};

export default AccessibilitySettings;
